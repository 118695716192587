import React, { useMemo } from 'react';
import {
  Box,
  Flex,
  merge,
  ThemeProvider,
  ThemeUICSSObject,
  useThemeUI,
} from 'theme-ui';

import { useBreakpointIndex } from '@theme-ui/match-media';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { RichText, Title } from '@sprinklr/shared-lib';

import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

import { TableProps } from './types';
import {
  ContainerPaddingValue,
  RichTextObject,
} from '@sprinklr/shared-lib/@types/entities';

import {
  TABLE_FOOTER_STYLES,
  TABLE_HEADING_SECTION_STYLES,
  TABLE_SUB_HEADLINE_STYLES,
} from './styles';

type Props = TableProps & {
  headline: string;
  tableSubHeadline?: RichTextObject;
  footerText?: RichTextObject;
  topMargin?: ContainerPaddingValue;
  bottomMargin?: ContainerPaddingValue;
};

const COLLAPISBLE_TABLE_TEMPLATE_COLORS = {
  tableSuccess: '#70BF54',
  tableDefault: 'rgba(0, 0, 0, 0.87)',
};

const CollapsibleTableTemplate = ({
  headline,
  tableSubHeadline,
  title,
  rows,
  defaultVisibleRows,
  footerText,
  topMargin,
  bottomMargin,
}: Props) => {
  const [mt, mb] = useContainerPadding({
    top: topMargin ?? 'MEDIUM',
    bottom: bottomMargin ?? 'MEDIUM',
  });
  const isMobile = useBreakpointIndex({ defaultIndex: 3 }) === 0;
  const { theme } = useThemeUI();

  const containerSx: ThemeUICSSObject = useMemo(
    () => ({ mt, mb, flexDirection: 'column', gap: '20px' }),
    [mt, mb],
  );

  const mergedTheme = useMemo(() => {
    return merge(theme, {
      colors: COLLAPISBLE_TABLE_TEMPLATE_COLORS,
    });
  }, [theme]);

  return (
    <ThemeProvider theme={mergedTheme}>
      <Flex sx={containerSx}>
        <Flex sx={TABLE_HEADING_SECTION_STYLES}>
          <Title title={headline} />
          {tableSubHeadline ? (
            <ThemeProvider theme={TABLE_SUB_HEADLINE_STYLES}>
              <RichText richTextObject={tableSubHeadline} />
            </ThemeProvider>
          ) : null}
        </Flex>
        {isMobile ? (
          <MobileTable
            title={title}
            rows={rows}
            defaultVisibleRows={defaultVisibleRows}
          />
        ) : (
          <DesktopTable
            title={title}
            rows={rows}
            defaultVisibleRows={defaultVisibleRows}
          />
        )}

        {footerText ? (
          <Box sx={TABLE_FOOTER_STYLES}>
            <RichText richTextObject={footerText} />
          </Box>
        ) : null}
      </Flex>
    </ThemeProvider>
  );
};

export default CollapsibleTableTemplate;
