import React, { useMemo } from 'react';
import { Box, Flex } from 'theme-ui';

import { ImageBlock } from '@sprinklr/shared-lib';

import { CollapsibleTableCell } from '../types';

import {
  getCellContentStyles,
  TABLE_CELL_CONTENT_STYLES,
  TABLE_ICON_STYLES,
} from './styles';

const CellContent = ({
  content,
  icons,
  iconAlignment,
  variant,
  cellContextSx,
}: CollapsibleTableCell) => {
  const icon = icons && icons.length ? icons[0] : null;

  const cellContentWrapperStyles = useMemo(
    () => getCellContentStyles(iconAlignment),
    [iconAlignment],
  );

  const cellContentStyles = useMemo(
    () => ({
      ...TABLE_CELL_CONTENT_STYLES,
      color: variant ?? 'tableDefault',
      ...cellContextSx,
    }),
    [variant, cellContextSx],
  );

  return (
    <Flex sx={cellContentWrapperStyles}>
      {icon ? <ImageBlock image={icon} svgSx={TABLE_ICON_STYLES} /> : null}
      <Box sx={cellContentStyles}>{content}</Box>
    </Flex>
  );
};

export default CellContent;
