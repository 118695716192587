import { GatsbyImageProps } from 'gatsby-plugin-image';
import { ThemeUICSSObject } from 'theme-ui';

export type CollapsibleTableCell = {
  id?: string;
  cellType: CollapsibleTableCellType;
  content: string;
  image?: GatsbyImageProps;
  icons?: Icon[];
  iconAlignment?: IconAlignment;
  cellContextSx?: ThemeUICSSObject;
  variant?: string;
};

export type CollapsibleTableRow = {
  id?: string;
  title?: string;
  cells: CollapsibleTableCell[];
  isSection?: boolean;
};

export enum CollapsibleTableCellType {
  CONTENT = 'CONTENT',
  IMAGE = 'IMAGE',
  ICONS = 'ICONS',
}

export type IconAlignment = 'LEFT' | 'RIGHT';

export type Icon = {
  file: {
    url: string;
  };
};

export type TableProps = {
  title: string;
  rows: CollapsibleTableRow[];
  defaultVisibleRows?: number;
};
