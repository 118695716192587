export const TABLE_SECTION_VARIANTS: {
  background: string;
  color: string;
}[] = [
  {
    background: 'rgba(0, 186, 233, 0.15)',
    color: '#00BAE9',
  },
  {
    background: 'rgba(250, 162, 27, 0.15)',
    color: '#FAA21B',
  },
  {
    background: 'rgba(17, 138, 203, 0.15)',
    color: '#118ACB',
  },
  {
    background: 'rgba(112, 191, 84, 0.15)',
    color: '#70BF54',
  },
  {
    background: 'rgba(210, 72, 166, 0.15)',
    color: '#D248A6',
  },
  {
    background: 'rgba(140, 67, 255, 0.15)',
    color: '#8C43FF',
  },
];
