import { ThemeUICSSObject } from 'theme-ui';
import { IconAlignment } from '../types';

export const TABLE_IMAGE_CELL_STYLES: ThemeUICSSObject = {
  height: 'auto',
  width: '110px',
  img: { width: 'auto' },
};

export const TABLE_ICONS_CELL_STYLES: ThemeUICSSObject = {
  flexDirection: 'column',
  gap: '8px',
  height: '100%',
  padding: '10px 16px',
};

export const TABLE_CELL_CONTENT_STYLES: ThemeUICSSObject = {
  fontWeight: '500',
  lineHeight: '143%',
  fontSize: '14px',
  letterSpacing: '0.15px',
};

export const TABLE_ICON_STYLES: ThemeUICSSObject = {
  width: '22px',
  height: '22px',
};

export const TABLE_CONTENT_STYLES: ThemeUICSSObject = { maxWidth: '180px' };

export const TABLE_ICONS_CELL_WRAPPER_STYLES: ThemeUICSSObject = {
  gap: '5px',
  alignItems: 'center',
};

export const getCellContentStyles = (
  iconAlignment: IconAlignment = 'LEFT',
): ThemeUICSSObject => {
  const alignmentStyles: ThemeUICSSObject =
    iconAlignment === 'RIGHT'
      ? {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        }
      : undefined;

  return {
    gap: '8px',
    alignItems: 'center',
    padding: '10px 16px',
    ...alignmentStyles,
  };
};
