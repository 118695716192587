/** @jsx jsx */
import React, { useMemo } from 'react';
import { Box, jsx, ThemeUICSSObject } from 'theme-ui';
// @ts-ignore
import ArrowDown from '@sprinklr/shared-lib/assets/svgs/down-arrow.svg';

const TABLE_DATA_STYLES: ThemeUICSSObject = {
  textAlign: 'center',
  padding: '20px 0 0 0',
};

const SVG_WRAPPER_STYLES: ThemeUICSSObject = { cursor: 'pointer' };

const TableFooter = ({
  noOfCols,
  onClick,
  expanded,
}: {
  noOfCols: number;
  onClick: () => void;
  expanded: boolean;
}) => {
  const svgStyles: ThemeUICSSObject = useMemo(
    () => ({
      width: '22px',
      height: '22px',
      transform: expanded ? 'rotate(180deg)' : '',
    }),
    [expanded],
  );

  return (
    <td colSpan={noOfCols} sx={TABLE_DATA_STYLES} role="cell">
      <Box sx={SVG_WRAPPER_STYLES} onClick={onClick}>
        <ArrowDown sx={svgStyles} />
      </Box>
    </td>
  );
};

export default TableFooter;
