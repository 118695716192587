import React, { useMemo } from 'react';
import { Flex } from 'theme-ui';

import { ImageBlock } from '@sprinklr/shared-lib';

import { chunk, getIconColumns } from '../../tableTemplate/components/V3/utils';

import { Icon } from '../types';

import {
  TABLE_ICONS_CELL_STYLES,
  TABLE_ICONS_CELL_WRAPPER_STYLES,
  TABLE_ICON_STYLES,
} from './styles';

const IconGrid = ({ icons }: { icons: Icon[] }) => {
  const iconRows = useMemo(
    () => chunk(icons, getIconColumns(icons.length)),
    [icons],
  );

  return (
    <Flex sx={TABLE_ICONS_CELL_STYLES}>
      {iconRows.map(row => {
        return (
          <Flex sx={TABLE_ICONS_CELL_WRAPPER_STYLES}>
            {row.map(icon => (
              <ImageBlock image={icon} svgSx={TABLE_ICON_STYLES} />
            ))}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default IconGrid;
