import React from 'react';
import { Box } from 'theme-ui';

import { ImageBlock } from '@sprinklr/shared-lib';
import CellContent from './CellContent';
import IconGrid from './IconGrid';

import { CollapsibleTableCell, CollapsibleTableCellType } from '../types';

import { TABLE_IMAGE_CELL_STYLES } from './styles';

const TableCell = (props: CollapsibleTableCell) => {
  const { cellType, image, icons } = props;

  switch (cellType) {
    case CollapsibleTableCellType.CONTENT:
      return <CellContent {...props} />;
    case CollapsibleTableCellType.IMAGE:
      return (
        <Box>
          <ImageBlock image={image} imageSx={TABLE_IMAGE_CELL_STYLES} />
        </Box>
      );
    case CollapsibleTableCellType.ICONS:
      return icons && icons.length ? <IconGrid icons={icons} /> : null;
    default:
      return null;
  }
};

export default TableCell;
