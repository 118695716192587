/** @jsx jsx */
import React, { useMemo } from 'react';
import { Box, jsx, ThemeUICSSObject } from 'theme-ui';
import { TABLE_SECTION_VARIANTS } from '../constants';

const TABLE_TEXT_STYLES: ThemeUICSSObject = {
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
};

const TableSection = ({
  title,
  noOfCols,
  isFirstRow,
  sectionVariant = 0,
}: {
  title: string;
  noOfCols: number;
  isFirstRow?: boolean;
  sectionVariant?: number;
}) => {
  const tableDataStyles: ThemeUICSSObject = useMemo(
    () => ({
      padding: isFirstRow ? '0px' : '30px 0 0 0',
      textAlign: 'center',
    }),
    [isFirstRow],
  );

  const { wrapperStyles, textStyles } = useMemo(() => {
    const { background, color } = TABLE_SECTION_VARIANTS[sectionVariant];

    const wrapperStyles = {
      padding: '10px 16px 10px 16px',
      background,
    };

    const textStyles: ThemeUICSSObject = {
      ...TABLE_TEXT_STYLES,
      color,
    };

    return { wrapperStyles, textStyles };
  }, [sectionVariant]);

  return (
    <td colSpan={noOfCols} sx={tableDataStyles}>
      <Box sx={wrapperStyles}>
        <Box sx={textStyles}>{title}</Box>
      </Box>
    </td>
  );
};

export default TableSection;
