/**@jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from 'theme-ui';

import CollapsibleTable from './CollapsibleTable';
import TableCell from './components/TableCell';

import { TABLE_HEADER_CELL_STYLES, TABLE_HEAD_STYLES } from './styles';

import { CollapsibleTableCell, TableProps } from './types';

const TableHeader = ({ cells }: { cells: CollapsibleTableCell[] }) => {
  return (
    <Fragment>
      {cells.map(header => (
        <th key={header.id} sx={TABLE_HEAD_STYLES} role="columnheader">
          <TableCell {...header} cellContextSx={TABLE_HEADER_CELL_STYLES} />
        </th>
      ))}
    </Fragment>
  );
};

const DesktopTable = (props: TableProps) => {
  return <CollapsibleTable {...props} tableHeader={TableHeader} />;
};

export default DesktopTable;
