/** @jsx jsx */
import React, {
  ComponentType,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, jsx } from 'theme-ui';

import withTableRow from './components/withTableRow';

import TableSectionComponent from './components/TableSection';
import TableDataRow from './components/TableDataRow';
import TableFooterComponent from './components/TableFooter';

import { TABLE_SECTION_VARIANTS } from './constants';

import {
  PADDING_BOX_STYLES,
  TABLE_CAPTION_STYLES,
  TABLE_CONTAINER_STYLES,
  TABLE_STYLES,
} from './styles';
import { CollapsibleTableCell, TableProps } from './types';

const TableSection = withTableRow(TableSectionComponent);
const TableRow = withTableRow(TableDataRow);
const TableFooter = withTableRow(TableFooterComponent);

const DEFAULT_VISIBLE_ROWS = 8;
const OFFSET = 100;

type Props = TableProps & {
  tableHeader: ComponentType<{ cells: CollapsibleTableCell[] }>;
};

const PaddingBox = () => <Box sx={PADDING_BOX_STYLES} />;

const CollapsibleTable = ({
  title,
  rows,
  tableHeader: TableHeader,
  defaultVisibleRows,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);

  const headerRows = rows[0].cells;
  const dataRows = rows.slice(1) || [];

  const noOfCols = headerRows.length;

  const visibleRows = defaultVisibleRows ?? DEFAULT_VISIBLE_ROWS;

  useEffect(() => {
    if (expanded) {
      setTableHeight(tableRef.current.scrollHeight + OFFSET);
    } else {
      setTableHeight(tableRef.current.offsetHeight + OFFSET);
    }
  }, [expanded]);

  const handleClick = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
  }, [setExpanded]);

  const updatedRows = useMemo(() => {
    let visibleCount = 0;
    let sectionCount = 0;

    return dataRows.map(item => {
      const isVisible = visibleCount <= visibleRows || expanded;
      visibleCount++;

      const sectionVariant = item.isSection
        ? sectionCount++ % TABLE_SECTION_VARIANTS.length
        : null;

      return {
        ...item,
        isVisible,
        sectionVariant,
      };
    });
  }, [dataRows, expanded]);

  const containerSx = useMemo(
    () => ({
      ...TABLE_CONTAINER_STYLES,
      transition: 'max-height 0.4s linear',
      maxHeight: expanded ? `${tableHeight}px` : 'initial',
      overflow: 'hidden',
    }),
    [expanded, tableHeight],
  );

  return (
    <Box sx={containerSx}>
      <table ref={tableRef} sx={TABLE_STYLES} role="table">
        <caption sx={TABLE_CAPTION_STYLES} role="caption">
          {title}
        </caption>

        <thead>
          <tr role="rowheader">
            <TableHeader cells={headerRows} />
          </tr>
        </thead>

        <tbody>
          {updatedRows.map((cell, idx) => {
            return (
              <Fragment key={cell.id}>
                {cell.isSection ? (
                  <TableSection
                    title={cell.title}
                    noOfCols={noOfCols}
                    isVisible={cell.isVisible}
                    sectionVariant={cell.sectionVariant}
                    isFirstRow={idx === 0}
                  />
                ) : (
                  <TableRow cells={cell.cells} isVisible={cell.isVisible} />
                )}
              </Fragment>
            );
          })}
        </tbody>
        <tfoot>
          {expanded ? (
            <PaddingBox />
          ) : (
            <TableFooter
              noOfCols={noOfCols}
              onClick={handleClick}
              expanded={expanded}
              isVisible={true}
            />
          )}
        </tfoot>
      </table>
    </Box>
  );
};

export default CollapsibleTable;
