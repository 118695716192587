import { Theme, ThemeUICSSObject } from 'theme-ui';

export const TABLE_CONTAINER_STYLES: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem',
  boxShadow: 3,
  width: '100%',
  maxWidth: ['90%', '80%'],
  margin: 'auto',
  borderRadius: '12px',
  overflowX: 'auto',
};

export const TABLE_STYLES: ThemeUICSSObject = {
  borderCollapse: 'collapse',
};

export const TABLE_HEAD_STYLES: ThemeUICSSObject = {
  textAlign: 'left',
  padding: '10px 0',
};

export const TABLE_HEADER_CELL_STYLES: ThemeUICSSObject = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '157%',
  letterSpacing: '0.1px',
};

export const TABLE_CAPTION_STYLES: ThemeUICSSObject = {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '30px',
  textAlign: 'left',
  margin: '20px 0',
};

export const TABLE_FOOTER_STYLES: ThemeUICSSObject = {
  p: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
  },
};

export const TABLE_SUB_HEADLINE_STYLES: Theme = {
  styles: {
    p: {
      maxWidth: '60%',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '-0.4px',
      margin: 'auto',
      color: 'sprText02',
    },
  },
};

export const TABLE_HEADING_SECTION_STYLES: ThemeUICSSObject = {
  flexDirection: 'column',
  gap: '10px',
  textAlign: 'center',
};

export const PADDING_BOX_STYLES: ThemeUICSSObject = { p: '25px 0' };
