/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, ThemeUICSSObject } from 'theme-ui';
import { CollapsibleTableCell } from '../types';

import TableCell from './TableCell';

const TABLE_DATA_STYLES: ThemeUICSSObject = {
  width: '150px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
};

const FIRST_CELL_FONT_WEIGHT = 600;
const DEFAULT_CELL_FONT_WEIGHT = 500;

const TableDataRow = ({ cells }: { cells: CollapsibleTableCell[] }) => {
  return (
    <Fragment>
      {cells?.map((cell, idx) => (
        <td key={cell.id} sx={TABLE_DATA_STYLES} role="cell">
          <TableCell
            {...cell}
            cellContextSx={{
              fontWeight:
                idx === 0 ? FIRST_CELL_FONT_WEIGHT : DEFAULT_CELL_FONT_WEIGHT,
            }}
          />
        </td>
      ))}
    </Fragment>
  );
};

export default TableDataRow;
