/**@jsx jsx */
import { Popover } from '@sprinklr/shared-lib';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { jsx, ThemeUICSSObject } from 'theme-ui';
import { Menu } from '../tableTemplate/components/V3/MobileTable';

import CollapsibleTable from './CollapsibleTable';
import TableCell from './components/TableCell';

import { TABLE_HEADER_CELL_STYLES, TABLE_HEAD_STYLES } from './styles';

// @ts-ignore
import StepperIcon from '@sprinklr/shared-lib/assets/svgs/stepper.svg';

import { CollapsibleTableCell, TableProps } from './types';

const MENU_STYLES: ThemeUICSSObject = {
  px: '32px',
  cursor: 'pointer',
  '&:hover': {
    bg: 'muted',
  },
};

const PopoverTableCell = ({
  cell,
  options,
  onSelect,
}: {
  cell: CollapsibleTableCell;
  options: string[];
  onSelect: (option: string) => void;
}) => (
  <Popover
    placement="bottom"
    body={
      <Menu items={options} onMenuClick={onSelect} menuItemSx={MENU_STYLES} />
    }
    popoverSx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Fragment>
      <TableCell {...cell} cellContextSx={TABLE_HEADER_CELL_STYLES} />
      <StepperIcon />
    </Fragment>
  </Popover>
);

const TableHeader = ({
  cells,
  options,
  onSelect,
}: {
  cells: CollapsibleTableCell[];
  options: string[];
  onSelect: (option: string) => void;
}) => {
  return (
    <Fragment>
      {cells.map((header, idx) => (
        <th key={header.id} sx={TABLE_HEAD_STYLES} role="columnheader">
          {idx === cells.length - 1 && options.length > 1 ? (
            <PopoverTableCell
              cell={header}
              options={options}
              onSelect={onSelect}
            />
          ) : (
            <TableCell {...header} cellContextSx={TABLE_HEADER_CELL_STYLES} />
          )}
        </th>
      ))}
    </Fragment>
  );
};

const MobileTable = ({ rows, ...props }: TableProps) => {
  const [activeRowIndex, setActiveRowIndex] = useState(2);

  const handleOptionChange = useCallback(
    (option: string) => {
      const headers = rows[0].cells;
      const idx = headers.findIndex(header => header.content === option);
      setActiveRowIndex(idx);
    },
    [rows],
  );

  const currentRows = useMemo(() => {
    const activedIndexes = [0, 1, activeRowIndex];
    return rows.map(section => ({
      ...section,
      cells: (section.cells || []).filter((_, j) => activedIndexes.includes(j)),
    }));
  }, [rows, activeRowIndex]);

  const options = useMemo(() => {
    const headerData = rows[0].cells;
    return headerData
      .slice(2)
      .filter((_, i) => i !== activeRowIndex - 2)
      .map(header => header.content);
  }, [rows, activeRowIndex]);

  return (
    <CollapsibleTable
      {...props}
      rows={currentRows}
      tableHeader={props => (
        <TableHeader
          {...props}
          options={options}
          onSelect={handleOptionChange}
        />
      )}
    />
  );
};

export default MobileTable;
