/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx, ThemeUICSSObject } from 'theme-ui';

interface WithTableRowProps {
  isVisible: boolean;
}

const withTableRow = <T extends object>(
  Component: React.ComponentType<T & WithTableRowProps>,
) => {
  const wrappedComponentName =
    Component.displayName || Component.name || 'TableRowWrapper';

  const WrapperComponent: React.FC<
    Omit<T, keyof WithTableRowProps> & WithTableRowProps
  > = props => {
    const { isVisible, ...restProps } = props;
    const sx: ThemeUICSSObject = useMemo(
      () => ({ display: isVisible ? '' : 'none' }),
      [isVisible],
    );
    return (
      <tr sx={sx} role="row">
        <Component {...(restProps as T)} isVisible={isVisible} />
      </tr>
    );
  };

  WrapperComponent.displayName = `withTableRow(${wrappedComponentName})`;

  return WrapperComponent;
};

export default withTableRow;
